import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import Unsubscribe from "../components/Unsubscribe"

const UnsubscribeForm = () => (
  <Layout backgroundColor={"white"} navColor={"black"}>
    <SEO
      title="Thesis | Unsubscribe"
      keywords={[]}
      description="Unsubscribe from the Thesis mailing list. Because there's only room for relevant emails."
    />
    <Unsubscribe />
  </Layout>
)

export default UnsubscribeForm
