import React from "react"
import Reveal from "react-reveal/Reveal"
import styles from "./styles.module.less"
import cta from "./img/UnsubscribeCTA.png"

const Unsubscribe = () => {
  return (
    <div className={styles.container}>
      <Reveal>
        <h2>
          <strong>There&rsquo;s only room for relevant emails.</strong>
        </h2>
        <div className={styles.textContainer}>
          <p>
            If you&rsquo;re not getting what you need from us, then we support
            your right to take your subscription elsewhere.{" "}
          </p>
          <div className={styles.fieldLabel}>
            <form
              method="post"
              action="http://www.pages03.net/ps_eroi/thesisoptout/Unsubscribe/"
              pageid={12068962}
              siteid={475458}
              parentpageid={12068960}
              name="form-name"
              data-netlify="true"
              netlify
              netlify-honeypot="bot-field"
            >
              <div>
                <label htmlFor="email"> Email </label>
                <input
                  className={styles.textInput}
                  type="text"
                  name="Email"
                  id="control_EMAIL"
                  label='<span style="font-size:16px;color: black;">Email Address</span>'
                  placeholder="Myemailaddresss@thisdomain.com"
                />
                <div className={styles.bg}>
                  <input
                    type="image"
                    className={styles.cta}
                    src={cta}
                    defaultValue
                  />
                </div>

                <input
                  type="hidden"
                  name="form-name"
                  defaultValue="StandardForm"
                />

                {/* DO NOT REMOVE HIDDEN FIELD sp_exp */}
                <input type="hidden" name="sp_exp" defaultValue="yes" />
                <button
                  className={styles.buttonCTA}
                  label="StaySubscribed"
                  type="staySubscribed"
                  target="_self"
                >
                  <span>
                    <a href="/subscribed-feedback" className={styles.linkCTA}>
                      Stay subscribed. Give feedback.
                    </a>
                  </span>
                </button>
              </div>
            </form>
          </div>{" "}
          {/* <tr>
                          <td id="errorMessageContainerId" className="formErrorMessages" />
                        </tr> */}
        </div>
      </Reveal>
    </div>
  )
}
export default Unsubscribe
